import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ReloadIcon from "../assets/icons/ReloadIcon";
import FormLayout from "../components/Form/FormLayout";
import FormTitle from "../components/Form/FormTitle";
import Tepic2 from "../assets/images/tepic2.jpeg";
import useBookingInfo from "../hook/useBookingInfo";
import islaTepic from "../assets/images/islaTepic.jpg";
import galeriasGdl from "../assets/images/galeriasGdl.jpg";

export default function Booking() {
  const navigate = useNavigate();
  const location = useLocation();
  const { form, setForm } = useBookingInfo();
  const [modalOpen, setModalOpen] = useState(false);
  const locationDummy = [
    {
      img: Tepic2,
      name: "Tepic",
      address:
        "Blvrd Luis Donaldo Colosio 680, Benito Juárez Oriente, 63175 Tepic, Nay.",
      phone: "31 11 91 70 84",
      location: "Tepic",
      mapa: "https://maps.app.goo.gl/JzkknjRPGyqaTAkF6",
    },
    {
      img: islaTepic,
      name: "Proximamente",
      address: "Puerto Vallarta",
      phone: "",
      location: "Puerto Vallarta",
      mapa: "https://goo.gl/maps/7Z8Z9ZQ7Q9Q2",
    },
    {
      img: galeriasGdl,
      name: "Proximamente",
      address: "Guadalajara",
      phone: "",
      location: "Guadalajara",
      mapa: "https://goo.gl/maps/7Z8Z9ZQ7Q9Q2",
    },
  ];

  return (
    <>
      {location?.pathname === "/booking" ? (
        <div className="container">
          <div className="pb-16">
            <div className="flex justify-between flex-wrap gap-2">
              <FormTitle>Elije su localizacion mas cercana</FormTitle>
              <span
                onClick={() => setModalOpen(true)}
                className="text-x-primary text-sm font-medium flex gap-2 items-center cursor-pointer"
              >
                <ReloadIcon /> Cambiar de ciudad
              </span>
            </div>
            {/* location card area start*/}
            <div className="flex gap-4 flex-wrap pt-10">
              {locationDummy?.map((item, index) => (
                <div
                  className="shadow rounded p-4 bg-white w-full md:w-[300px]"
                  key={index}
                >
                  <div className="flex md:block gap-4">
                    <img
                      src={item?.img}
                      alt={item?.name}
                      className="rounded w-[80px] md:w-full h-[80px] md:h-[180px] object-cover"
                    />
                    <div className="mt-4 mb-8">
                      <strong className="text-sm mb-1 block">
                      {item?.name === "Tepic" ? `Plaza ${item?.name}` : item?.name}
                      </strong>
                      <address className="whitespace-pre-line text-sm font-normal not-italic">
                        {item?.address}
                      </address>
                      <a
                        href={`tel:${item?.phone}`}
                        className="text-x-primary font-medium"
                      >
                        {item?.phone}
                      </a>
                    </div>
                  </div>
                  <button
                    className={`text-white bg-x-primary py-4 px-10 rounded font-semibold w-full ${item.name === "Proximamente" ? "opacity-50 cursor-not-allowed" : ""}`}
                    onClick={() => {
                      if (item.name !== "Proximamente") {
                        setForm({ ...form, location: item });
                        navigate("/booking/experience");
                      }
                    }}
                    disabled={item.name === "Proximamente"}
                  >
                    Selecciona localizacion
                  </button>
                </div>
              ))}
            </div>
            {/* location card area end*/}
          </div>
        </div>
      ) : (
        <FormLayout form={form}>
          <Outlet />
        </FormLayout>
      )}

      {/* modal */}
      {modalOpen ? (
        <>
          <div
            id="backdropModal"
            onClick={() => setModalOpen(false)}
            className="bg-black/75 fixed top-0 left-0 w-full h-full"
          ></div>
          <div
            id="modal"
            className="bg-white border rounded p-5 md:p-10 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] md:w-[80%] max-h-[500px] overflow-auto py-5"
          >
            <div className="flex items-center gap-3">
              <p className="text-[#446a81c7] text-lg font-semibold uppercase relative whitespace-nowrap">
                Selecciona el lugar de diversion
              </p>
              <span className="w-full bg-[#A9B3C1] h-[1px]"></span>
            </div>
            <div className="text-[#1b242fe5]">
              <h2 className="text-2xl font-bold"></h2>
              <div className="flex gap-4 flex-wrap">
                <span
                  className="underline cursor-pointer hover:after:content-none"
                  onClick={() => setModalOpen(false)}
                >
                  Mexico
                </span>
                <span
                  className="underline cursor-pointer hover:after:content-none"
                  onClick={() => setModalOpen(false)}
                >
                  USA
                </span>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
