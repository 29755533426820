import React, { createContext, useState } from "react";

const BookingInfoContext = createContext({});

export const BookingInfoProvider = ({ children }) => {
  const [form, setForm] = useState({
    location: {},
    experience: "",
    guests: "",
    game:"",
    date: {
      day: "",
      time: "",
    },
  });
  console.log(form);
  return (
    <BookingInfoContext.Provider value={{ form, setForm }}>
      {children}
    </BookingInfoContext.Provider>
  );
};

export default BookingInfoContext;
