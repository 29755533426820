import React, { useEffect } from "react";
import { useSearchParams, useNavigate  } from 'react-router-dom';
import PagoRecibido from "../assets/images/PagoRecibido.png";
import axios from 'axios';
import { convertToAmPm } from "../utils/timeUtils";

const SuccessPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [dataReservacion, setDataReservacion] = React.useState({});
    const session_id = searchParams.get('session_id');
    const navigate = useNavigate(); // Hook for navigation

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? "" : date.toLocaleDateString('en-GB'); // 'en-GB' for dd/mm/yyyy format
      };

    useEffect(() => {
        const updateBooking = async () => {
            if (!session_id) {
                console.error("No session id found.");
                return;
            }
            try {
                const response = await axios.put(`${process.env.REACT_APP_BASEURL}/reservations/status/${session_id}`, {status: "booked"});
                console.log(response.data);
                setDataReservacion(response.data);

                let calculo_total = response.data.amount * response.data.players;

                await axios.post(`${process.env.REACT_APP_BASEURL}/emails/confirmation`, {email: response.data.email, clientName: response.data.clientName, date: response.data.date, location: response.data.location, game: response.data.game, players: response.data.players});
                await axios.post(`${process.env.REACT_APP_BASEURL}/emails/reservation`, {amount: calculo_total, clientName: response.data.clientName, date: formatDate(response.data.date), horario:convertToAmPm(convertToAmPm(dataReservacion?.date)) , location: response.data.location, game: response.data.game, players: response.data.players});
                await axios.post(`${process.env.REACT_APP_BASEURL}/transactions`, {amount: response.data.amount, paymentMode: "card", modo :"online","campobatallamoney": response.data.amountIndiv,"campobatallaqty": response.data.players,"campobatallatotal":calculo_total,client: response.data.clientId});
            } catch (error) {
                console.error(error);
            }
        };
    
        updateBooking();
    }, [session_id,navigate]); // Dependency array should include session_id if it's expected to change

    return (
        <div className="flex flex-col items-center justify-center h-screen">
    <img src={PagoRecibido} alt="Pago Recibido" className="mb-4" />
    <h1 className="text-3xl font-bold text-center text-white mb-4">Detalles de la reservacion:</h1>
    <p className="text-white text-xl mb-2">Nombre de la reservacion: {dataReservacion?.clientName}</p>
    <p className="text-white text-xl mb-2">Fecha: {dataReservacion?.date ? formatDate(dataReservacion?.date) : ""} {convertToAmPm(dataReservacion?.date)}</p>
    
    <p className="text-white text-xl mb-2">Lugar: {dataReservacion?.location === "Tepic" ? `Plaza ${dataReservacion?.location}` : dataReservacion?.location}</p>
    
    <p className="text-white text-xl mb-2">Juego: {dataReservacion?.game}</p>
    <p className="text-white text-xl mb-2">Numero de jugadores: {dataReservacion?.players}</p>
    <p className="text-white text-xl mb-2">
        <button
          onClick={() => window.location.href = "https://virtualityworld.com.mx"}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Regresar inicio
        </button>
      </p>
</div>

    );
}

export default SuccessPage;
