import React from "react";

function InfoIcon() {
  return (
    <svg width="1.6em" height="1.6em" viewBox="0 0 24 24" version="1.1">
      <g stroke="#0076ff" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-1235.000000, -75.000000)" fill-rule="nonzero">
          <g id="Group" transform="translate(1235.000000, 75.000000)">
            <circle
              id="Oval"
              stroke="#0076ff"
              fill="#EFF3F7"
              cx="12"
              cy="12"
              r="11.5"
            ></circle>
            <path
              d="M12,9 C12.5522847,9 13,9.44771525 13,10 L13,18 C13,18.5522847 12.5522847,19 12,19 C11.4477153,19 11,18.5522847 11,18 L11,10 C11,9.44771525 11.4477153,9 12,9 Z M12,5 C12.5522847,5 13,5.44771525 13,6 C13,6.55228475 12.5522847,7 12,7 C11.4477153,7 11,6.55228475 11,6 C11,5.44771525 11.4477153,5 12,5 Z"
              fill="#0076ff"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default InfoIcon;
