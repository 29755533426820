import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-5xl font-medium mb-10 text-x-gray">Bienvenido!</h1>
        <button
          className="text-white bg-x-primary py-4 px-10 rounded"
          onClick={() => navigate("/booking")}
        >
          Entrar
        </button>
      </div>
    </div>
  );
}
