export default function ReloadIcon() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 21" className="text-2xl">
      <path
        d="M4.917 17.994H.75a.75.75 0 0 1 0-1.5h2.637a10.493 10.493 0 0 1-1.875-6.22 10.433 10.433 0 0 1 3.237-7.358A10.46 10.46 0 0 1 12.014 0c1.388 0 2.743.271 4.027.804a.748.748 0 0 1-.288 1.442.734.734 0 0 1-.288-.058 8.95 8.95 0 0 0-3.451-.689 8.967 8.967 0 0 0-6.228 2.5 8.943 8.943 0 0 0-2.775 6.307A8.976 8.976 0 0 0 4.5 15.464v-2.721a.75.75 0 1 1 1.5.001v4.5a.75.75 0 0 1-.75.75h-.238a.364.364 0 0 1-.045.003l-.05-.003zm7.078 3.002c-1.392 0-2.75-.272-4.038-.808a.747.747 0 0 1-.404-.981.748.748 0 0 1 .98-.405 8.961 8.961 0 0 0 3.462.694 8.96 8.96 0 0 0 6.234-2.508 8.941 8.941 0 0 0 2.767-6.31 8.973 8.973 0 0 0-1.497-5.158v2.724a.75.75 0 0 1-1.499 0v-4.5a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-2.635a10.503 10.503 0 0 1 1.882 6.215 10.438 10.438 0 0 1-3.228 7.362 10.46 10.46 0 0 1-7.274 2.925z"
        fill="#0076FF"
        fillRule="nonzero"
      ></path>
    </svg>
  );
}
