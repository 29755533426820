export default function VirtualIcon() {
  return (
    <svg viewBox="0 0 21 24" width="26" height="23" data-testid="vr-guy">
      <g transform="translate(.342 .293)" fill-rule="nonzero" fill="none">
        <path
          data-name="neck"
          d="M10.814 17.579l-4.28-2.444.296-.63 6.461 3.338c.1.052.206.092.314.118.79.19 1.573-.344 1.75-1.192L16.94 9.16c.06-.289.102-.582.125-.877.28-3.58-2.192-6.724-5.52-7.024l-1.903-.172a4.477 4.477 0 0 0-.242-.015C6.745.977 4.521 3.214 4.433 6.069l-.165 5.313c-.012.387-.12.763-.311 1.09l-2.841 4.844 7.428 4.748 2.27-4.485zm-2.02 5.468L.2 17.552 3.4 12.095c.13-.221.203-.475.21-.737l.165-5.313C3.875 2.8 6.404.257 9.422.365c.092.004.184.01.275.018L11.6.554c3.69.333 6.43 3.82 6.12 7.788a7.715 7.715 0 0 1-.138.973l-1.586 7.608c-.256 1.23-1.39 2.003-2.535 1.728a2.025 2.025 0 0 1-.455-.17l-1.506-.779-2.705 5.345z"
          fill="#A0AAB3"
        ></path>
        <path
          data-name="face"
          d="M10.45.946l2.616.304 2.316 1.437 1.439 2.322.5 1.908-1.939 10.62-1.12.72-1.196-.234-6.414-3.27L5.37 7.447z"
          fill="#FFFFFF"
        ></path>
        <path
          data-name="back"
          d="M8.422 22.342c-1.069-.659-3.657-2.295-7.764-4.908l3.016-5.142a1.97 1.97 0 0 0 .266-.934l.164-5.301C4.199 3 6.537.607 9.327.71c.081.003 3.03.38 3.111.388-4.085 4.388-4.552 9.737-1.4 16.047-.43.858-1.303 2.568-2.616 5.197z"
          fill="#DCE8F1"
        ></path>
        <path
          data-name="lines"
          d="M9.788 19.62a.317.317 0 0 1 .447-.141c.159.094.217.308.13.48l-1.571 3.088-8.595-5.495L3.4 12.095c.13-.221.203-.475.21-.737l.165-5.313C3.875 2.8 6.404.257 9.422.365c.092.004.184.01.275.018L11.6.554c3.69.333 6.43 3.82 6.12 7.788a7.715 7.715 0 0 1-.138.973l-1.586 7.608c-.256 1.23-1.39 2.003-2.535 1.728a2.025 2.025 0 0 1-.455-.17l-6.46-3.34a.366.366 0 0 1-.155-.471c.079-.176.275-.25.44-.166l6.46 3.34c.1.051.206.09.314.117.79.19 1.573-.344 1.75-1.192L16.94 9.16c.06-.289.102-.582.125-.877.28-3.58-2.192-6.724-5.52-7.024l-1.903-.172a4.477 4.477 0 0 0-.242-.015C6.745.977 4.521 3.214 4.433 6.069l-.165 5.313c-.012.387-.12.763-.311 1.09l-2.841 4.844 7.428 4.749 1.244-2.446z"
          fill="#A0AAB3"
        ></path>
        <path
          data-name="straps"
          d="M4.568 4.087l.235-.66 9.876 4.066-1.033 3.428-.315-.112c-5.254-1.885-8.398-3.046-9.446-3.49l.242-.658c1.01.428 4.047 1.551 9.098 3.364l.636-2.112-9.293-3.826z"
          fill="#A0AAB3"
        ></path>
        <path
          data-name="goggles"
          fill="#DCE8F1"
          d="M19.374 7.466l-4.743-.952-1.474 5.288 4.938 1.952L19.731 7.8z"
        ></path>
        <path
          data-name="goggle-frame"
          d="M15.069 6.912a.237.237 0 0 0-.27.176l-1.3 4.37a.26.26 0 0 0 .144.315l4.154 1.655a.225.225 0 0 0 .026.009c.127.034.256-.05.287-.187l1.201-5.204c.027-.153-.058-.287-.187-.312l-4.055-.822zm.065-.69l4.11.816c.486.097.807.599.717 1.122l-.012.057-1.2 5.204c-.12.516-.605.83-1.085.702a.85.85 0 0 1-.095-.032l-4.155-1.655c-.444-.177-.683-.697-.544-1.184l1.245-4.366c.133-.468.572-.753 1.019-.665z"
          fill="#A0AAB3"
        ></path>
        <ellipse
          data-name="ear-inner"
          fill="#FFFFFF"
          cx="9.208"
          cy="7.779"
          rx="1.973"
          ry="2.121"
        ></ellipse>
        <path
          data-name="ear-outer"
          d="M9.072 10.485c-1.267 0-2.292-1.123-2.292-2.505s1.025-2.505 2.292-2.505c1.268 0 2.293 1.123 2.293 2.505s-1.025 2.505-2.293 2.505zm0-.707c.902 0 1.635-.804 1.635-1.798s-.733-1.797-1.635-1.797c-.901 0-1.634.803-1.634 1.797 0 .994.733 1.798 1.634 1.798z"
          fill="#A0AAB3"
        ></path>
      </g>
    </svg>
  );
}
