import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InnerArrowIcon from "../../assets/icons/InnerArrowIcon";
import img from "../../assets/images/deadwoodv.avif";
import bgImg from "../../assets/images/deadwoodv_bg.jpg";
import DefensaSwat from "../../assets/images/DefensaSwat.jpg";
import GuerraMarte from "../../assets/images/GuerraMarte.jpg";
import Rebellion from "../../assets/images/Rebellion.jpg";
import Jardin from "../../assets/images/Jardin.jpg";
import Rancho from "../../assets/images/Rancho.jpg";
import useBookingInfo from "../../hook/useBookingInfo";
import axios from "axios";
import moment from "moment-timezone";
import { convertToAmPm } from "../../utils/timeUtils";
import { ThreeDots } from 'react-loader-spinner';

function Confirmation() {
  const [stripe, setStripe] = useState(null);
  const stripe_key = "pk_live_51HDAAcJLoV4LkDEzCOHvPdpYKs0IKG44vodbihHZNv2e2n2L7pcFVg9Nq2ZbCshlJ4Wpsv2u3HfkK0FHV2gk93H700QxFfA8Hc";

  const { form, setForm } = useBookingInfo();
  // form state start
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [promo, setPromo] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false); // New state for terms acceptance

  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(stripe_key));
    } else {
      // If not yet available, wait and try again
      const intervalId = setInterval(() => {
        if (window.Stripe) {
          setStripe(window.Stripe(stripe_key));
          clearInterval(intervalId);
        }
      }, 10);
    }
  }, []);
  
  console.log(phone);
  console.log("FF " +  form.precio + " --- " + form.guests + " " + form.experience + " " + form.date.day + " " + form.date.time + "../../assets/images/" + form?.imageName);

  const dateTimeString = `${form.date.day}T${form.date.time}:00`;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateTime = moment.tz(dateTimeString, userTimeZone).tz('America/Hermosillo').toISOString();

  // form state end
  var calculo_total = form?.guests * form?.precio;
  calculo_total = isNaN(calculo_total) ? 0 : calculo_total;
  var iva = calculo_total * 0.16;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  var formatted_total = formatter.format(calculo_total);

  const handleCheckout = async (sessionid) => {
    console.log("sessionid", sessionid);
    if (!stripe) {
      console.error("Stripe has not initialized.");
      return;
    }
    const articulos = "Campo de batalla";
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASEURL}/payments`, {
        amount: calculo_total,
        articulos,
        sessionid
      });
      console.log(response.data);
      console.log("//**/*" + response.data.id);
      const result = await stripe.redirectToCheckout({
        sessionId: response.data.id
      });
      if (result.error) {
        console.error('Checkout error:', result.error.message);
      }
    } catch (error) {
      console.error('Error making payment request:', error);
    }
  };

  const handleReservation = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASEURL}/reservations`, {
        phone,
        firstName,
        lastName,
        email,
        status: "pending",
        date: dateTime,
        players: form.guests,
        experience: form.experience,
        location: form?.location?.location,
        game: form?.experience,
        amountIndiv: form?.precio,
        modo:"online",
      });
      console.log(response);
      console.log("Session id 1 " + response.data._id);
      handleCheckout(response.data._id);
    } catch (error) {
      console.error('Error submitting reservation:', error);
    }
  };

  const images = {
    "Rancho.jpg": Rancho,
    "DefensaSwat.jpg": DefensaSwat,
    "GuerraMarte.jpg": GuerraMarte,
    "Rebellion.jpg": Rebellion,
    "Jardin.jpg": Jardin
  };

  const getImage = (imageName) => {
    return images[imageName] || img; // Fallback to a default image if not found
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "" : date.toLocaleDateString('en-GB'); // 'en-GB' for dd/mm/yyyy format
  };

  return (
    <div className="grid lg:grid-cols-2 ">
      {/* desktop left side */}
      <div className="hidden lg:block">
        <div
          className="w-full h-full hidden lg:block"
          style={{
            backgroundImage: `url(${bgImg})`,
            backgroundPosition: "center 70%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "fixed",
            left: 0,
            top: 0,
            zIndex: -1,
          }}
        ></div>
        <div className="flex items-center justify-center h-[350px] lg:h-screen py-5 bg-green-300 lg:bg-inherit">
          <div className="flex flex-row lg:flex-col text-center text-[#E9ECF3]">
            <img
              src={getImage(form?.imageName)}
              alt=""
              className="w-[350px] rounded shadow-x-card-xl m-auto"
            />
            <div>
              <h2 className=" font-bold text-[44px]">{form?.experience}</h2>
              <div>
                <p className="pb-4">{formatDate(form?.date?.day)} · {convertToAmPm(form?.date?.time)}</p>
                <p className="font-medium">{form?.location?.location === "Tepic" ? `Plaza ${form?.location?.location}` : form?.location?.location}</p>
                <address className="whitespace-pre-line text-sm font-medium not-italic ">
                  {form?.location?.address}
                </address>
                <a
                  href={form?.location?.mapa}
                  className="flex gap-2 justify-center mt-1.5 text-sm"
                >
                  Ver en mapa <InnerArrowIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* laptop top side */}
      <div className="hidden md:block lg:hidden">
        <div
          className="h-[350px]  py-5 bg-no-repeat bg-cover w-full"
          style={{
            backgroundImage: `url(${bgImg})`,
            backgroundPosition: "center 70%",
          }}
        >
          <div className="flex gap-10 text-start text-[#E9ECF3] px-10">
            <img
              src={img}
              alt=""
              className="w-[250px] h-[363px] object-cover rounded shadow-x-card-xl z-10"
            />
            <div>
              <h2 className=" font-bold text-[56px]">Forum Tepic</h2>
              <div>
                <p className="pb-4">{formatDate(form?.date?.day)} · {convertToAmPm(form?.date?.time)}</p>
                <p className="font-medium">Forum TEPIC</p>
                <address className="whitespace-pre-line text-sm font-medium not-italic ">
                {form?.location?.address} 
                </address>
                <a href="#!" className="flex gap-2  mt-1.5 text-sm font-bold">
                  View on map <InnerArrowIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* mobile top side */}
      <div className="block md:hidden">
        <div
          className="h-[280px]  py-5 bg-no-repeat bg-cover w-full"
          style={{
            backgroundImage: `url(${bgImg})`,
            backgroundPosition: "center 70%",
          }}
        >
          <div className="flex gap-10 justify-center text-start text-[#E9ECF3] px-10">
            <img
              src={getImage(form?.imageName)}
              alt=""
              className="w-[200px] h-[280px] object-cover rounded shadow-x-card-xl z-10"
            />
          </div>
        </div>
        <div className="px-5 pt-10">
          <h2 className=" font-bold text-[28px]">Deadwood Valley</h2>
          <div>
            <p className="pb-4">{formatDate(form?.date?.day)} · {convertToAmPm(form?.date?.time)}</p>
            <p className="font-medium">CAUSEWAY BAY</p>
            <address className="whitespace-pre-line text-sm font-medium not-italic ">
              {form?.location?.address}
            </address>
            <a href={form?.location?.mapa} className="flex gap-2  mt-1.5 text-sm font-bold">
              View on map <InnerArrowIcon />
            </a>
          </div>
        </div>
      </div>

      {/* right side ---booking form */}
      <div className="bg-[#1f0d5f]  min-h-screen">
        <div className="pt-16 pb-10 lg:pt-10 lg:pb-10 px-8">
          <div className="flex items-center gap-3">
            <p className="text-[#ffffff] text-lg font-semibold uppercase  relative whitespace-nowrap">
              RESUMEN DE LA RESERVACION
            </p>
            <span className="w-full bg-[#A9B3C1] h-[1px]"></span>
          </div>
          <div className="flex justify-between py-4 text-x-gray">
            <p>{form?.guests} Jugadores x {form?.precio} MXN</p>
            <p>MXN${formatted_total}</p>
          </div>
          <div className="flex justify-between py-4 text-x-gray">
            <p>IVA</p>
            <p>MXN${iva}</p>
          </div>
          <div className="flex justify-between text-lg font-semibold pb-5 text-x-gray">
            <p>TOTAL</p>
            <p>MXN${formatted_total}</p>
          </div>
          <div className="flex items-center gap-3 mt-3">
            <p className="text-[#446a81c7] text-lg font-semibold uppercase  relative whitespace-nowrap text-x-gray">
              ENTRA TU INFORMACION
            </p>
            <span className="w-full bg-[#A9B3C1] h-[1px]"></span>
          </div>

          {/* form */}
          <div className="flex flex-col md:flex-row pt-3 w-full">
            <div className="relative w-full md:w-1/2">
              <input
                type="text"
                name=""
                placeholder="Nombre"
                className="p-4 focus:outline-none border border-b-0 md:border-r-0 rounded-t md:rounded-tl w-full text-[#446a81c7] "
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
            <div className="relative w-full md:w-1/2">
              <input
                type="text"
                name=""
                placeholder="Apellidos"
                className="p-4 focus:outline-none border  w-full text-[#446a81c7] "
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className=" relative">
            <input
              type="email"
              name=""
              placeholder="Correo Electronico"
              className="p-4 focus:outline-none border border-t-0 w-full text-[#446a81c7] "
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className=" relative">
            <PhoneInput
              className="custom-phone"
              country={"mx"}
              value={phone}
              onChange={(value) => setPhone(value)}
            />
          </div>

          <div className="flex items-center text-[#1B242F] text-sm md:text-md font-medium pt-5">
            <div>
              <input 
                type="checkbox" 
                id="one" 
                name="one" 
                className="w-5 h-5" 
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
              />
            </div>

            <label htmlFor="one" className="cursor-pointer ml-3 text-x-gray">
              Acepto haber leído los términos y condiciones así como las advertencias colocadas en las instalaciones del establecimiento comercial de Virtuality World, así como en la página de internet: www.virtualityworld.com.mx, por lo que manifiesto mi conformidad con su contenido. Para mayor referencia de click aquí para ver los "Términos y Condiciones" del servicio otorgado.
            </label>
          </div>
          <div className="flex items-center text-[#1B242F] text-sm md:text-md font-medium mt-3">
            <div>
              <input type="checkbox" id="two" name="two" className="w-5 h-5" />
            </div>

            <label htmlFor="two" className="cursor-pointer ml-3 text-x-gray">
              Acepto que mi correo electrónico se utilice para recibir boletines y promociones.
            </label>
          </div>
          

          {/* form */}
          <div className="pt-4">
            <button
              disabled={!firstName || !lastName || !email || phone?.length < 6 || !acceptTerms}
              className="text-white bg-x-primary py-5 px-10 rounded font-semibold w-full  disabled:bg-x-gray disabled:opacity-50 disabled:cursor-no-drop"
              onClick={handleReservation}
            >
              {loading ? (
                <ThreeDots  color="#fff" height={20} width={20} />
              ) : (
                'RESERVAR'
              )}
            </button>
          </div>

          <p className="text-sm text-text-secondary pt-3 text-x-gray">
            Al hacer clic en "Reservar ahora", aceptas nuestros términos y has leído nuestros{" "}
            <a href="https://virtualityworld.com.mx/aviso-de-privacidad/" className="text-x-primary">
              Terminos y Condiciones
            </a>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
