import React, { useState, useEffect } from "react";
import axios from "axios";

const VistaCalendar = () => {
    const [reservations, setReservations] = useState([]);

    // Function to fetch reservations
    const getCalendar = async () => {
        const today = new Intl.DateTimeFormat('en-CA', {
            timeZone: 'America/Mexico_City',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).format(new Date());
        
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}/reservations?startDate=${today}`);
            //const response = await axios.get(`${process.env.REACT_APP_BASEURL}/reservations?startDate=2024-08-03`);

            console.log(response.data);
            setReservations(response.data);
        } catch (error) {
            console.error("Error fetching reservations:", error);
        }
    }

    // useEffect to fetch data on component mount and set interval for refreshing data
    useEffect(() => {
        getCalendar();
        const interval = setInterval(getCalendar, 60000); // Refresh every minute
        return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, []);

    return (
        <div className="container mx-auto p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold text-center text-white">Siguientes Jugadores</h2>
                <img
                    src="https://virtualityworld.com.mx/wp-content/uploads/2024/06/logo-sm-3.webp"
                    alt="Virtuality World Logo"
                    className="h-16"
                />
            </div>
            <table className="min-w-full bg-white text-center">
                <thead>
                    <tr className="w-full bg-gray-800 text-white text-center">
                        <th className="py-3 px-4 uppercase font-semibold text-sm ">Hora</th>
                        <th className="py-3 px-4 uppercase font-semibold text-sm ">Juego</th>
                        <th className="py-3 px-4 uppercase font-semibold text-sm ">Jugadores</th>
                    </tr>
                </thead>
                <tbody>
                {reservations.filter(reservation => reservation.game).map((reservation, index) => (
                        <tr key={index} className="text-gray-700">
                            <td className="py-3 px-4 border">{reservation.time}</td>
                            <td className="py-3 px-4 border">{reservation.game || 'DISPONIBLE'}</td>
                            <td className="py-3 px-4 border">
  {reservation?.participantsbooked} / {reservation?.availableparticipants + reservation?.participantsbooked}
</td>                            </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default VistaCalendar;
