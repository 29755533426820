import React, { useEffect } from "react";
import axios from 'axios';
import PagoRechazado from "../assets/images/PagoRechazado.png";
import { useSearchParams,useNavigate  } from 'react-router-dom';

const CancelPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const session_id = searchParams.get('session_id');
    const navigate = useNavigate(); // Hook for navigation


    useEffect(() => {
        const updateBooking = async () => {
            if (!session_id) {
                console.error("No session id found.");
                return;
            }
            try {
                const response = await axios.put(`${process.env.REACT_APP_BASEURL}/reservations/status/${session_id}`, {status: "cancelled"});
                console.log(response.data);
            } catch (error) {
                console.error(error);
            }
        };
    
        updateBooking();
        // Set up the timeout for redirection
        const timer = setTimeout(() => {
            window.location.href = 'https://virtualityworld.com.mx/'; // Redirecting to the URL
        }, 5000); // Redirect after 5000 milliseconds or 5 seconds

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, [session_id,navigate]); // Dependency array should include session_id if it's expected to change

    return (
        <div className="flex justify-center items-center h-screen">
            <img src={PagoRechazado} alt="Pago Rechazado" />
        </div>
    );
}

export default CancelPage;
