// timeUtils.js
export function convertToAmPm(time24) {
    if (!time24) {
      return '';
    }
  
    const [hour, minute] = time24.split(':').map(Number);
  
    if (isNaN(hour) || isNaN(minute)) {
      return '';
    }
  
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; // Convert '0' to '12'
    return `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
  }
  