export default function ArrowDown({ rotate }) {
  return (
    <svg
      viewBox="-7, -12, 14, 24"
      width="1em"
      height="1em"
      className={`transition-all ${rotate}`}
    >
      <g fill="none">
        <path
          d="M-5,0 L5,10 M-5,0 L5,-10"
          stroke="#0076ff"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </g>
    </svg>
  );
}
