import React from "react";

function VideoIcon() {
  return (
    <svg viewBox="0 0 156 156" height="24" width="24">
      <g fill-rule="nonzero" fill="none">
        <circle fill="#0076FF" cx="78" cy="78" r="78"></circle>
        <path
          d="M68.87 54.745l33.403 20.877a3 3 0 0 1 0 5.088L68.87 101.588a3 3 0 0 1-4.59-2.544V57.289a3 3 0 0 1 4.59-2.544z"
          fill="#FFF"
        ></path>
      </g>
    </svg>
  );
}

export default VideoIcon;
