import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useBookingInfo from "../../hook/useBookingInfo";
import FormTitle from "./FormTitle";

export default function Party() {
  const navigate = useNavigate();
  const { form, setForm } = useBookingInfo();
  const guestsDummyNumber = [1, 2, 3, 4, 5, 6, 7, 8];
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedGuests, setSelectedGuests] = useState(null);

  const getPartyData = (number) => {
    if (number < 8) {
      setSelectedGuests(number);
      setPopupOpen(true);
    } else {
      setForm({ ...form, guests: number });
      navigate("/booking/date");
    }
  };

  const handleConfirm = () => {
    setForm({ ...form, guests: selectedGuests });
    setPopupOpen(false);
    navigate("/booking/date");
  };

  return (
    <div className="p-0 lg:p-3 xl:p-5 rounded  w-full pb-16">
      <FormTitle>Selecciona el numero de jugadores</FormTitle>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 pt-8">
        {guestsDummyNumber?.map((number, index) => (
          <div
            key={index}
            onClick={() => getPartyData(number)}
            className="border-2 border-white hover:border-[#8DA5BA] text-x-gray hover:text-x-primary hover:shadow-x-xl py-5 flex flex-col justify-center items-center text-gray rounded cursor-pointer"
          >
            <span className="text-3xl font-semibold">{number}</span>
            <span className="text-xs">Jugador</span>
          </div>
        ))}
      </div>

      {/* Popup */}
      {popupOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-lg font-bold mb-4">Atención</h2>
            <p className="mb-4">
            Si la reservación es para menos de 8 jugadores, es posible que otros se unan hasta llenar el cupo
            </p>
            <div className="flex justify-end gap-4">
              <button
                className="bg-blue-500 text-white py-2 px-4 rounded"
                onClick={handleConfirm}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
