import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDown from "../../assets/icons/ArrowDown";
import EditsIcon from "../../assets/icons/EditsIcon";
import useBookingInfo from "../../hook/useBookingInfo";
import { convertToAmPm } from "../../utils/timeUtils";
import dayjs from "dayjs";

export default function FormLayout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOn, setMenuOn] = useState(false);
  const { form, setForm } = useBookingInfo();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      navigate("/booking");
    };
    window.addEventListener("load", handleBeforeUnload);
    return () => {
      window.removeEventListener("load", handleBeforeUnload);
    };
  }, [navigate]);

  const handleFooterMenu = () => {
    setMenuOn(() => !menuOn);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = dayjs(dateString);
    return date.isValid() ? date.format("DD-MM-YYYY") : "";
  };

  if (location?.pathname === "/booking/confirmation") {
    return children;
  }

  return (
    <div className="container">
      <div className="flex items-start gap-5">
        {children}

        <div
          id="booking_form"
          className="p-5 pt-0 md:pt-5 pr-0 md:pr-5 bg-white md:rounded w-full md:w-96 md:mt-[120px] fixed md:sticky md:top-5 md:overflow-auto left-0 bottom-0 z-20"
        >
          <div className="flex md:hidden items-center justify-between">
            <h2
              onClick={handleFooterMenu}
              className="text-lg text-x-primary cursor-pointer font-semibold w-full flex gap-2 items-center"
            >
              <ArrowDown
                rotate={!menuOn ? "rotate-[270deg]" : "rotate-[90deg]"}
              />
              Summary
            </h2>
            <button
              disabled={
                !form?.experience ||
                !form?.guests ||
                !form?.date?.day ||
                !form?.date?.time
              }
              className="text-white bg-x-primary py-2 px-10 font-semibold disabled:opacity-50 "
              onClick={() => {
                navigate("/booking/confirmation");
              }}
            >
              Continue
            </button>
          </div>
          <div
            className={`pr-5 md:pr-0 h-[380px] md:h-auto transition-all ${
              !menuOn ? "-mb-[400px]" : ""
            } md:mb-0`}
          >
            <h2 className="text-lg md:text-2xl font-medium pb-5 text-[#313542]">
              SU RESERVACION
            </h2>
            <h5 className="uppercase text-xs text-x-graySegound font-medium">
              LOCALIZACION
            </h5>
            <p>
              <span
                className="flex gap-2 justify-between items-center py-1 cursor-pointer"
                onClick={() => {
                  navigate("/booking");
                  setForm({
                    ...form,
                    location: {},
                    experience: "",
                    guests: "",
                    date: {},
                  });
                  setMenuOn(false);
                }}
              >
                <strong className="block text-lg text-x-primary ">
                  {form?.location?.name}
                </strong>
              
              </span>
              <address className="text-[10px] font-normal not-italic text-[#A0A0A0]">
                {form?.location?.address}
              </address>
              <a
                href={`tel:${form?.location?.phone}`}
                className="text-x-primary font-medium text-xs"
              >
                {form?.location?.phone}
              </a>
            </p>
            <h5 className="uppercase text-xs text-x-graySegound font-medium pt-5">
              EXPERIENCIA
            </h5>

            <span
              className="h-8 flex gap-2 justify-between items-center py-1 cursor-pointer"
              onClick={() => {
                navigate("/booking/experience");
                setForm({ ...form, experience: "", guests: "", date: {} });
                setMenuOn(false);
              }}
            >
              <strong className="block text-lg text-x-primary ">
                {form?.experience}
              </strong>
             
            </span>

            <h5 className="uppercase text-xs text-x-graySegound font-medium pt-5">
              Jugadores
            </h5>
            <span
              className="h-8 flex gap-2 justify-between items-center py-1 cursor-pointer"
              onClick={() => {
                navigate("/booking/party");
                setForm({ ...form, guests: "", date: {} });
                setMenuOn(false);
              }}
            >
              <strong className="block text-lg text-x-primary ">
                {form?.guests}
              </strong>
              
            </span>
            <h5 className="uppercase text-xs text-x-graySegound font-medium pt-5">
              DIA Y HORA
            </h5>
            <span
              className="h-8 flex gap-2 justify-between items-center py-1 cursor-pointer"
              onClick={() => {
                navigate("/booking/date");
                setForm({ ...form, date: {} });
                setMenuOn(false);
              }}
            >
              <strong className="block text-lg text-x-primary ">
                {form?.date?.day ? formatDate(form?.date?.day) : ""} {form?.date?.time ? convertToAmPm(form?.date?.time) : ""}
              </strong>
              
            </span>
            <p className="mb-4"></p>
          </div>
          <div className="hidden md:block">
            <button
              disabled={
                !form?.experience ||
                !form?.guests ||
                !form?.date?.day ||
                !form?.date?.time
              }
              className="text-white bg-x-primary py-2 px-10 rounded font-semibold w-full disabled:opacity-50 "
              onClick={() => {
                navigate("/booking/confirmation");
              }}
            >
              Continuar
            </button>
            <span className="pt-3 text-[10px] text-[#A0A0A0] block leading-3 ">
              El precio puede variar dependiendo las horas o días de juego .
            </span>
          </div>
        </div>
        <div
          onClick={() => setMenuOn(!menuOn)}
          className={` ${
            !menuOn ? "hidden" : "block"
          } md:hidden backdrop-brightness-50 bg-black/30 fixed left-0 top-0 w-full h-full z-10`}
        ></div>
      </div>
    </div>
  );
}








/*
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDown from "../../assets/icons/ArrowDown";
import EditsIcon from "../../assets/icons/EditsIcon";
import useBookingInfo from "../../hook/useBookingInfo";
import { convertToAmPm } from "../../utils/timeUtils";

export default function FormLayout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOn, setMenuOn] = useState(false);
  const { form, setForm } = useBookingInfo();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      navigate("/booking");
    };
    window.addEventListener("load", handleBeforeUnload);
    return () => {
      window.removeEventListener("load", handleBeforeUnload);
    };
  }, [navigate]);

  const handleFooterMenu = () => {
    setMenuOn(() => !menuOn);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "" : date.toLocaleDateString('en-GB'); // 'en-GB' for dd/mm/yyyy format
  };

  if (location?.pathname === "/booking/confirmation") {
    return children;
  }

  return (
    <div className="container">
      <div className="flex items-start gap-5">
        {children}

        <div
          id="booking_form"
          className="p-5 pt-0 md:pt-5 pr-0 md:pr-5 bg-white md:rounded w-full md:w-96 md:mt-[120px] fixed md:sticky md:top-5 md:overflow-auto left-0 bottom-0 z-20"
        >
          <div className="flex md:hidden items-center justify-between">
            <h2
              onClick={handleFooterMenu}
              className="text-lg text-x-primary cursor-pointer font-semibold w-full flex gap-2 items-center"
            >
              <ArrowDown
                rotate={!menuOn ? "rotate-[270deg]" : "rotate-[90deg]"}
              />
              Summary
            </h2>
            <button
              disabled={
                !form?.experience ||
                !form?.guests ||
                !form?.date?.day ||
                !form?.date?.time
              }
              className="text-white bg-x-primary py-2 px-10 font-semibold disabled:opacity-50 "
              onClick={() => {
                navigate("/booking/confirmation");
              }}
            >
              Continue
            </button>
          </div>
          <div
            className={`pr-5 md:pr-0 h-[380px] md:h-auto transition-all ${
              !menuOn ? "-mb-[400px]" : ""
            } md:mb-0`}
          >
            <h2 className="text-lg md:text-2xl font-medium pb-5 text-[#313542]">
              SU RESERVACION
            </h2>
            <h5 className="uppercase text-xs text-x-graySegound font-medium">
              LOCALIZACION
            </h5>
            <p>
              <span
                className="flex gap-2 justify-between items-center py-1 cursor-pointer"
                onClick={() => {
                  navigate("/booking");
                  setForm({
                    ...form,
                    location: {},
                    experience: "",
                    guests: "",
                    date: {},
                  });
                  setMenuOn(false);
                }}
              >
                <strong className="block text-lg text-x-primary ">
                  {form?.location?.name}
                </strong>
                <span
                  className="cursor-pointer"
                  onClick={() => navigate("/booking")}
                >
                  <EditsIcon />
                </span>
              </span>
              <address className="text-[10px] font-normal not-italic text-[#A0A0A0]">
                {form?.location?.address}
              </address>
              <a
                href={`tel:${form?.location?.phone}`}
                className="text-x-primary font-medium text-xs"
              >
                {form?.location?.phone}
              </a>
            </p>
            <h5 className="uppercase text-xs text-x-graySegound font-medium pt-5">
              EXPERIENCIA
            </h5>

            <span
              className="h-8 flex gap-2 justify-between items-center py-1 cursor-pointer"
              onClick={() => {
                navigate("/booking/experience");
                setForm({ ...form, experience: "", guests: "", date: {} });
                setMenuOn(false);
              }}
            >
              <strong className="block text-lg text-x-primary ">
                {form?.experience}
              </strong>
              <span className="cursor-pointer">
                {form?.experience ? <EditsIcon /> : null}
              </span>
            </span>

            <h5 className="uppercase text-xs text-x-graySegound font-medium pt-5">
              Jugadores
            </h5>
            <span
              className="h-8 flex gap-2 justify-between items-center py-1 cursor-pointer"
              onClick={() => {
                navigate("/booking/party");
                setForm({ ...form, guests: "", date: {} });
                setMenuOn(false);
              }}
            >
              <strong className="block text-lg text-x-primary ">
                {form?.guests}
              </strong>
              <span className="cursor-pointer">
                {form?.guests ? <EditsIcon /> : null}
              </span>
            </span>
            <h5 className="uppercase text-xs text-x-graySegound font-medium pt-5">
              DIA Y HORA
            </h5>
            <span
              className="h-8 flex gap-2 justify-between items-center py-1 cursor-pointer"
              onClick={() => {
                navigate("/booking/date");
                setForm({ ...form, date: {} });
                setMenuOn(false);
              }}
            >
              <strong className="block text-lg text-x-primary ">
                {form?.date?.day ? formatDate(form?.date?.day) : ""} {convertToAmPm(form?.date?.time)}
              </strong>
              <span className="cursor-pointer">
                {form?.date?.time ? <EditsIcon /> : null}
              </span>
            </span>
            <p className="mb-4"></p>
          </div>
          <div className="hidden md:block">
            <button
              disabled={
                !form?.experience ||
                !form?.guests ||
                !form?.date?.day ||
                !form?.date?.time
              }
              className="text-white bg-x-primary py-2 px-10 rounded font-semibold w-full disabled:opacity-50 "
              onClick={() => {
                navigate("/booking/confirmation");
              }}
            >
              Continuar
            </button>
            <span className="pt-3 text-[10px] text-[#A0A0A0] block leading-3 ">
              El precio puede variar dependiendo las horas o días de juego .
            </span>
          </div>
        </div>
        <div
          onClick={() => setMenuOn(!menuOn)}
          className={` ${
            !menuOn ? "hidden" : "block"
          } md:hidden backdrop-brightness-50 bg-black/30 fixed left-0 top-0 w-full h-full z-10`}
        ></div>
      </div>
    </div>
  );
}

*/