import React, { useState } from "react";
import Calendar from "react-calendar";
import ArrowNext from "../assets/icons/ArrowNext";
import ArrowPrev from "../assets/icons/ArrowPrev";

function Calender({ onClickDay }) {
  const [date, onChange] = useState(new Date());
  return (
    <>
      <div className="w-full p-2.5 bk-cln">
        <Calendar
          onChange={onChange}
          value={date}
          minDate={new Date()}
          next2Label={false}
          prev2Label={false}
          nextLabel={<ArrowNext />}
          prevLabel={<ArrowPrev />}
          onClickDay={onClickDay}
        />
      </div>
    </>
  );
}

export default Calender;
