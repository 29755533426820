import React from "react";
import { XCircleIcon } from '@heroicons/react/outline';

const Modal = ({ onClose, videoUrl }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg relative">
        <button onClick={onClose} className="absolute top-4 right-4 text-red-500">
          <XCircleIcon className="h-6 w-6" />
        </button>
        <iframe
          title="Video Preview"
          src={videoUrl}
          width="560"
          height="315"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Modal;