import { BrowserRouter, Route, Routes,HashRouter } from "react-router-dom";
import Confirmation from "./components/Form/Confirmation";
import Dates from "./components/Form/Date";
import Experience from "./components/Form/Experience";
import Party from "./components/Form/Party";
import { BookingInfoProvider } from "./context/BookingInfoProvider";
import Booking from "./pages/Booking";
import Home from "./pages/Home";
import CancelPage from "./pages/CancelPage";
import SuccessPage from "./pages/SuccessPage";
import VistaCalendar from "./pages/VistaCalendar";

function App() {
  return (
    <HashRouter >
      <BookingInfoProvider> 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="booking" element={<Booking />}>
            <Route path="experience" element={<Experience />} />
            <Route path="party" element={<Party />} />
            <Route path="date" element={<Dates />} />
            <Route path="confirmation" element={<Confirmation />} />
          </Route>
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/cancel" element={<CancelPage />} />
          <Route path="/vista" element={<VistaCalendar />} />
        </Routes>
      </BookingInfoProvider>
    </HashRouter>
  );
}


export default App;
