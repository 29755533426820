import dayjs from "dayjs";
import { useEffect, useState } from "react";
import useBookingInfo from "../../hook/useBookingInfo";
import Calender from "../Calender";
import FormTitle from "./FormTitle";
import axios from 'axios';
import { convertToAmPm } from "../../utils/timeUtils";

export default function Dates() {
  const { form, setForm } = useBookingInfo();
  const [storeDate, setStoreDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [activeIndex, setActiveIndex] = useState(0);
  const [times, setTime] = useState([]);

  const toggleTime = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASEURL + "/reservations/avaibility?date=" + storeDate + "&location=" + form?.location?.location + "&game=" + form?.experience)
      .then((res) => {
        setTime(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [storeDate]);

  const timeDummy = [
    { time: "12:10" },
    { time: "12:50" },
    { time: "13:50" },
    { time: "13:40" },
    { time: "14:10" },
    { time: "14:40" },
    { time: "14:50" },
    { time: "15:10" },
    { time: "15:30" },
    { time: "15:40" },
    { time: "15:50" },
    { time: "16:10" },
    { time: "16:30" },
    { time: "16:50" },
    { time: "16:60" },
    { time: "20:40" },
  ];

  const getPartyDay = (value) => {
    const selectedDate = dayjs(value).format("YYYY-MM-DD");
    console.log("Selected date " + selectedDate);
    setStoreDate(selectedDate);
    setForm({
      ...form,
      date: { day: dayjs(value).format("DD-MM-YYYY"), time: "" },
    });
  };

  const getPartyTime = (time) => {
    setForm({ ...form, date: { day: storeDate, time: time } });
  };

  return (
    <>
      <div className="p-0 lg:p-3 xl:p-5 rounded w-full pb-16">
        <FormTitle>Selecciona el día y la hora</FormTitle>
        <Calender onClickDay={(value, event) => getPartyDay(value)} />
        <h2 className="text-lg md:text-xl font-medium py-5 text-x-gray">Selecciona la hora de juego (Horario Local)</h2>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 pt-8">
          {times?.map((date, index) => {
            const isSameExperience = date?.game === form?.experience || !date?.game;
            const availableParticipants = date?.availableparticipants ?? 8;
            const participantsBooked = date?.participantsbooked ?? 0;
            console.log("Available participants: " + availableParticipants + " Participants booked: " + participantsBooked);
            const isAvailable = !date?.availableparticipants || date?.availableparticipants - date?.participantsbooked > 0; // Ensure availability
            const slotClass = isSameExperience && isAvailable ? "bg-white text-x-primary border-x-primary" : "bg-gray-200 text-red-600 border-gray-200";
            return (
              <div
                key={index}
                onClick={() => {
                  if (isSameExperience && isAvailable) {
                    getPartyTime(date?.time);
                    toggleTime(index);
                  }
                }}
                className={`border-2 rounded-lg p-4 flex flex-col items-center justify-between h-48 ${slotClass} ${activeIndex === index && isSameExperience ? "shadow-lg" : ""} cursor-pointer`}
              >
                <div className="text-center">
                  <span className="text-3xl font-bold">{convertToAmPm(date?.time)}</span>
                </div>
                <hr className="w-full border-t-2 border-gray-300 my-2" />
                <div className="text-center">
                  {isSameExperience ? (
                    <>
                      <span className="text-sm font-semibold">{form?.experience}: </span>
                      <span className="text-sm font-semibold">Disponibles: {availableParticipants} / {availableParticipants+participantsBooked}</span>
                    </>
                  ) : (
                    <>
                      <span className="text-sm font-semibold">Juego: {form?.experience} </span>
                      <span className="text-sm font-semibold">COMPLETO<br /></span>
                      <span className="text-sm font-semibold">Disponible: {date?.game} - {availableParticipants} / {availableParticipants+participantsBooked}</span>

                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
