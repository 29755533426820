import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../assets/icons/InfoIcon";
import VideoIcon from "../../assets/icons/VideoIcon";
import VirtualIcon from "../../assets/icons/VirtualIcon";
import gameimg from "../../assets/images/deadwoodv-landscape.avif";
import DefensaSwat from "../../assets/images/DefensaSwat.jpg";
import GuerraMarte from "../../assets/images/GuerraMarte.jpg";
import Rebellion from "../../assets/images/Rebellion.jpg";
import Jardin from "../../assets/images/Jardin.jpg";
import Rancho from "../../assets/images/Rancho.jpg";

import useBookingInfo from "../../hook/useBookingInfo";
import FormTitle from "./FormTitle";
import Modal from "./Modal";

function Experience() {
  const navigate = useNavigate();
  const { form, setForm } = useBookingInfo();
  const gamesDummy = [
    {
      name: "Rancho Embrujado",
      img: Rancho,
      imageName: "Rancho.jpg",
      price: 250,
      //tag: "novedad",
      videoUrl: "https://player.vimeo.com/video/965554089",
    },
    {
      name: "Defensa SWAT",
      img: DefensaSwat,
      imageName: "DefensaSwat.jpg",
      price: 250,
      //tag: "best seller",
      videoUrl: "https://player.vimeo.com/video/951213149",
    },
    {
      name: "Guerra en Marte",
      img: GuerraMarte,
      imageName: "GuerraMarte.jpg",
      price: 250,
      //tag: "best seller",
      videoUrl: "https://player.vimeo.com/video/951213332",
    },
    {
      name: "Rebelión Robotica",
      img: Rebellion,
      imageName: "Rebellion.jpg",
      price: 250,
      videoUrl: "https://player.vimeo.com/video/965554022",
    },
    {
      name: "Jardin Perdido",
      img: Jardin,
      imageName: "Jardin.jpg",
      price: 250,
      videoUrl: "https://player.vimeo.com/video/965553958",
    },
    // Add more games with videoUrl here
  ];

  const [showModal, setShowModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const openModal = (url) => {
    if (url !== "#") {
      setVideoUrl(url);
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getExperinceData = (item) => {
    navigate("/booking/party");
    setForm({ ...form, experience: item?.name,imageName:item?.imageName,precio:item?.price });
  };

  return (
    <div className="p-0 lg:p-3 xl:p-5 rounded w-full pb-16">
      <FormTitle>Seleciona tu experiencia</FormTitle>
      
      {showModal && <Modal onClose={closeModal} videoUrl={videoUrl} />}
      <div
        id="card"
        className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8"
      >
        {gamesDummy?.map((item, index) => (
          <div key={index}>
            <div
              className="w-full relative cursor-pointer"
              onClick={() => getExperinceData(item)}
            >
              <img src={item?.img} alt="" className="w-full rounded-xl" />
              <div className="absolute left-2 top-3 cursor-default">
                <span
                  className={
                    item?.tag === "best seller" || item?.tag === "novedad"
                      ? `bg-[#FFA826] text-[#1B242F] font-semibold text-sm py-2 px-5 rounded-lg uppercase`
                      : item?.tag === "new"
                      ? "bg-[#E43838] text-white font-semibold text-sm py-2 px-5 rounded-lg uppercase"
                      : null
                  }
                >
                  {item?.tag}
                </span>
              </div>
              <div className="absolute right-4 top-4">
                <a
                  href="#!"
                  target="_blank"
                  className="group bg-[#0077ff8e] p-0.5 rounded-full flex items-center overflow-hidden relative"
                >
                  <InfoIcon />
                  <span className="text-sm font-bold text-white px-5 whitespace-nowrap transition-all -mr-[116px] group-hover:mr-0">
                    Conoce Mas
                  </span>
                </a>
              </div>
              <div className="absolute right-4 top-14">
                <button
                  className="group bg-[#0077ff8e] p-0.5 rounded-full flex items-center relative overflow-hidden"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the parent onClick
                    openModal(item.videoUrl);
                  }}
                >
                  <VideoIcon />
                  <span className="text-sm font-bold text-white px-5 whitespace-nowrap transition-all -mr-[126px] group-hover:mr-0">
                    Video preview
                  </span>
                </button>
              </div>
            </div>
            <h3
              className="text-x-primary text-xl font-bold pt-2 cursor-pointer"
              onClick={() => getExperinceData(item)}
            >
              {item?.name}
            </h3>
            <div className="pt-1 flex justify-between text-xs text-text-secondary">
              <div className="flex gap-2">
                <VirtualIcon /> <span>1-8</span>
              </div>
              <span>
                MXN$250{" "}
                <span className="text-[0.8em] uppercase">/Jugador</span>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Experience;
